<template>
    <div class="page-container">
        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start">
                        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" class="logo" />
                        <h3 class="md-title">stock.ftvaccess.fr</h3>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-menu md-direction="bottom-end" :mdCloseOnClick="true" :mdCloseOnSelect="true">
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content>
                                <md-menu-item v.if="$store.getters.logged" @click="$router.push('/stock')">
                                    <span>Recherche de programmes</span>
                                    <md-icon>search</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/password')">
                                    <span>Changer mot de passe</span>
                                    <md-icon>lock</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/confidentialite')">
                                    <span>Politique de confidentialité</span>
                                    <md-icon>policy</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/cgu')">
                                    <span>CGU</span>
                                    <md-icon>description</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/legal')">
                                    <span>Mentions légales</span>
                                    <md-icon>gavel</md-icon>
                                </md-menu-item>
                                <md-menu-item  @click="logout">
                                    <span>Déconnexion</span>
                                    <md-icon>logout</md-icon>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
            </md-app-toolbar>
            <md-app-content class="md-scrollbar md-alignment-top-center md-layout">
                <md-content>
                    <h1 class="md-title">Politique de confidentialité</h1>
                    <h2 class="md-subheading">(Octobre 2022)</h2>
                    <h3 class="text-justify">Qui est le responsable de traitement de vos données sur le site https://stock.ftvaccess.fr (ci-après dénommé le « Site ») ?</h3>
                    <p class="md-body-1 text-justify">
                        france.tv studio, dont le siège social est situé 26 rue d’Oradour sur Glane– 75015 Paris (ci-après dénommé « l’Editeur »), est le responsable du traitement des données personnelles collectées et traitées dans le cadre du Site pour les finalités explicitées ci-dessous.<br />
                        <br/>
                        La présente politique de confidentialité s’applique à tout le Site.
                    </p>
                    <h3 class="text-justify">Quelles sont les données que nous collectons ?</h3>
                    <p class="md-body-1 text-justify">
                        Dans le cadre de votre utilisation du Site, nous traitons des données personnelles collectées auprès de vous afin de pouvoir créer et gérer un compte utilisateur : nous recueillons un nom, prénom et une adresse mail.
                    </p>
                    <h3 class="text-justify">Finalités de la collecte et base légale ?</h3>
                    <p class="md-body-1 text-justify">
                        Nous utilisons tout ou partie des données ainsi collectées pour atteindre une ou plusieurs des finalités de traitement explicitées ci-dessous :
                    </p>
                    <ul>
                        <li class="md-body-1 text-justify">
                            <strong>Création et gestion d’un compte utilisateur « Client »: </strong>les données collectées permettent à nos services internes de france.tv studio de créer un compte utilisateur. La création de compte « Client » permet aux clients d’accéder au contenu du Site, nous permettent de gérer notre relation avec nos clients en conservant une base de données à jour de nos clients actuels ou potentiels. 
                        </li>
                    </ul>
                    <p class="md-body-1 text-justify">
                        Base légale : Intérêt légitime. 
                    </p>
                    <ul>
                        <li class="md-body-1 text-justify">
                            <strong>Assurer le respect des lois et réglementations et des conditions d'utilisation du Site : </strong>les données personnelles collectées sont traitées afin d’assurer le respect des lois et réglementations en vigueur
                        </li>
                    </ul>
                    <p class="md-body-1 text-justify">
                        Base légale : Obligations légales ou réglementaires
                    </p>
                    <ul>
                        <li class="md-body-1 text-justify">
                            <router-link to="/cgu">Conditions Générales d’Utilisation (CGU)</router-link> : faire accepter les CGU
                        </li>
                    </ul>
                    <p class="md-body-1 text-justify">
                        Base légale : Consentement. Vous validez les conditions exposées dans les CGU lors de votre première connexion au Site.
                    </p>
                    <ul>
                        <li class="md-body-1 text-justify">
                            <strong>Répondre aux demandes d’exercice de droits: </strong>Les données personnelles ainsi collectées nous permettent également de répondre à toutes demandes d’exercice de droits. Le traitement de ces données nous permet de vous identifier, de vous recontacter facilement et de vous apporter une réponse adaptée et personnalisée à votre situation.
                        </li>
                    </ul>
                    <p class="md-body-1 text-justify">
                        Base légale : Prescriptions légales.
                    </p>
                    <h3 class="text-justify">Avec qui sont partagées mes données ?</h3>
                    <p class="md-body-1 text-justify">
                        Pour pouvoir assurer et vous fournir l’ensemble des services décrits précédemment, l’accès à certaines de vos données doit être rendu possible à différents acteurs :
                    </p>
                    <ul>
                        <li class="md-body-1 text-justify">
                            Les services internes de france.tv studio.
                        </li>
                        <li class="md-body-1 text-justify">
                            Les tiers habilités par la loi ou la réglementation (autorités judiciaires et/ou administratives ou votre fournisseur d’accès à Internet).   
                        </li>
                        <li class="md-body-1 text-justify">
                            Nos prestataires sous-traitants : Nous faisons appel à des prestataires sous-traitants pour leur expertise notamment dans les domaines d’activité suivants : développement et hébergement du Site .<br />
                            Noms des prestataires :<br />
                            <ul>
                                <li class="md-body-1 text-justify">
                                    Projektiles (et sous-traitant –collaborateur externe)
                                </li>
                                <li class="md-body-1 text-justify">
                                    OVH
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3 class="text-justify">Combien de temps mes données sont-elles conservées ?</h3>
                    <p class="md-body-1 text-justify">
                        Nous ne conserverons vos données personnelles sous une forme permettant votre identification que pendant la durée strictement nécessaire aux finalités explicitées précédemment.
                    </p>
                    <ul>
                        <li class="md-body-1 text-justify">
                            Création et gestion de compte : <br />
                            Toutes les données sont conservées en base active pendant la durée nécessaire à la réalisation de l’objectif (finalité du traitement) ayant justifié la collecte/enregistrement des données. En pratique, les données seront alors facilement accessibles dans l’environnement de travail immédiat pour les services opérationnels qui sont en charge de ce traitement.<br />
                            <br />
                            Le compte est désactivé lorsque la collaboration prend fin au bout de 6 mois<br />
                            <br />
                            Lorsque le compte est désactivé, les données personnelles sont alors conservées en archive intermédiaire car présentent encore un intérêt administratif pour france.tv studio ou parce qu’elles doivent être conservées pour répondre à une obligation légale. Les données peuvent alors être consultées de manière ponctuelle et motivée par des personnes spécifiquement habilitées, pendant une durée de 5 années. <br />
                            <br />
                            Au-delà, les données personnelles sont anonymisées.
                        </li>
                    </ul>
                    <h3 class="text-justify">Quels sont mes droits et comment les exercer ?</h3>
                    <p class="md-body-1 text-justify">
                        Vous bénéficiez des droits d'accès, de rectification, d’effacement, de limitation, d’opposition et à la portabilité de vos données personnelles, ainsi que de définir des directives sur leur sort après votre décès, dans les limites et conditions prévues par ces textes et la jurisprudence. 
                    </p>
                    <p class="md-body-1 text-justify">
                        Pour en savoir plus sur la portée de ces différents droits : <a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles" target="_blank">https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles</a>.
                    </p>
                    <p class="md-body-1 text-justify">
                        Vous pouvez exercer vos droits en adressant votre demande par courrier à france.tv studio – Référent Compliance/DPO – 26 rue d’Oradour sur Glane – 75015 Paris ou par mail : <a href="mailto:dpo.ftvs@francetv.fr">dpo.ftvs@francetv.fr</a> 
                    </p>
                    <p class="md-body-1 text-justify">
                        Enfin, vous avez le droit d’introduire une réclamation auprès de la CNIL par courrier ou sur son site Internet.
                    </p>
                    <h3 class="text-justify">Cette politique peut-elle être modifiée ?</h3>
                    <p class="md-body-1 text-justify">
                        La présente politique de confidentialité peut être modifiée. En cas de modifications importantes, nous vous informerons en affichant un avis sur notre site. 
                    </p>
                    <h3 class="text-justify">Gestion de Cookie</h3>
                    <p class="md-body-1 text-justify">
                        Vous êtes informé qu’un cookie est déposé sur l’équipement terminal utilisé afin de consulter le Site. <br />
                        Les cookies sont de petits fichiers texte qui sont utilisés pour stocker des éléments d’information.
                    </p>
                    <h3 class="text-justify">Quelles utilisations faisons-nous des cookies ?</h3>
                    <p class="md-body-1 text-justify">
                        <u>Cookies Nécessaires</u> : Les cookies nécessaires sont cruciaux pour les fonctions de base du Site et celui-ci ne fonctionnera pas comme prévu sans eux. Ces cookies ne stockent aucune donnée personnellement identifiable.<br />
                        <br />
                        Vous trouverez ci-dessous les cookies que nous utilisons dans cette catégorie ainsi que le détail de leur finalité :
                    </p>
                    <md-table>
                        <md-table-row>
                            <md-table-head>Cookie</md-table-head>
                            <md-table-head>Durée</md-table-head>
                            <md-table-head>Description</md-table-head>
                        </md-table-row>

                        <md-table-row>
                            <md-table-cell class="text-justify">access_token</md-table-cell>
                            <md-table-cell class="text-justify">Session</md-table-cell>
                            <md-table-cell class="text-justify">Permet de garder votre authentification sur le site. Le cookie est supprimé automatiquement lorsque le navigateur est fermé</md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-content>
            </md-app-content>
        </md-app>
    </div>
</template>
      

<script>

export default {
  name: 'Confidentialite',
  data(){
      return {
          loader: null,
      }
  },
  methods:{
      logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch(() => {
                this.$alert("Il n'a pas été possible de vous deconnecter",'Error','error');
            })
        }
  }
  
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
  .text-justify{
    text-align: justify;
  }
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>